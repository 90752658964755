import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import ElementUI from 'element-ui';
import less from 'less'
import 'element-ui/lib/theme-chalk/index.css';
import Function from './function/index' // 引入


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(less)
Vue.use(Function) // 添加
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
