import Vue from "vue"
import Router from "vue-router"
Vue.use(Router)
import Home from "../view/Home.vue"
import Product from "../view/Product.vue"
import Firm from "../view/Firm.vue"
import Message from "../view/Message.vue"
import Callme from "../view/Callme.vue"
export default new Router({
    linkExactActiveClass: "active",
    routes: [
        {
            path: "/",
            redirect: "/Product",
        },
        {
            path: "/home",
            name: "Home",
            component: Home
        },
        {
            path: "/Product",
            name: "Product",
            component: Product
        },
        {
            path: "/Firm",
            name: "Firm",
            component: Firm
        },
        {
            path: "/Message",
            name: "Message",
            component: Message
        },
        {
            path: "/Callme",
            name: "Callme",
            component: Callme
        },
    ],
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }

})