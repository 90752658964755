export default {
    install(Vue) {
        /**
         * 复制 方法
         * str
         */
        Vue.prototype.$copy = function (str) {  //复制函数
            if ((navigator.userAgent.match(/(iPhone|iPod|iPad);?/i))) {//ios系统
                window.getSelection().removeAllRanges();//这段代码必须放在前面否则无效
                let inputDom = document.createElement('input');
                let copyDom = document.getElementsByTagName("body")[0];//要复制文字的节点
                copyDom.appendChild(inputDom);//把标签添加给body
                inputDom.style.opacity = 0;//设置input标签设置为透明(不可见)
                inputDom.value = str;//修改文本框的内容
                let range = document.createRange();
                //选中需要复制的节点
                range.selectNode(inputDom);
                //执行选中元素
                window.getSelection().addRange(range);
                inputDom.select();
                inputDom.setSelectionRange(0, inputDom.value.length);//适配高版本ios
                //执行copy操作
                let successful = document.execCommand('copy');
                let messageText = successful ? '请将此链接复制至手机或电脑浏览器打开并完成下载。' : '链接复制失败！'
                console.log(messageText)
                //移除选中的元素
                window.getSelection().removeAllRanges();
                copyDom.removeChild(inputDom)
            } else {
                //其他系统
                let inputDom = document.createElement('input');
                let copyDom = document.getElementsByTagName("body")[0]
                copyDom.appendChild(inputDom);//把标签添加给body 
                inputDom.style.opacity = 0;//设置input标签设置为透明(不可见)
                inputDom.value = str;//修改文本框的内容
                inputDom.select();//选中文本
                //执行选中元素
                let successful = document.execCommand("copy");//执行浏览器复制命令console.log
                let messageText = successful ? '请将此链接复制至手机或电脑浏览器打开并完成下载。' : '链接复制失败！'
                console.log(messageText)
                copyDom.removeChild(inputDom)
            }
        }
        Vue.prototype.$browserType = function () {
            let browser = {
                versions: function () {
                    var u = navigator.userAgent
                    // app = navigator.appVersion;
                    return {         //移动终端浏览器版本信息
                        trident: u.indexOf('Trident') > -1, //IE内核
                        presto: u.indexOf('Presto') > -1, //opera内核
                        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                        iPad: u.indexOf('iPad') > -1, //是否iPad
                        webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
                    };
                }(),
                language: (navigator.browserLanguage || navigator.language).toLowerCase()
            }
            if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
                var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
                if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    //在微信中打开
                    return 'wechart'
                } else if (browser.versions.ios) {
                    return 'ios'
                } else if (browser.versions.android) {
                    return 'android'
                } else {
                    return 'other'
                }
            } else {
                //否则就是PC浏览器打开
                return 'PC'
            }
        }


    }
}