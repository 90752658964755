<template>
  <div class="side-bar">
    <el-tooltip class="item" effect="dark" placement="left">
      <div slot="content" class="content">
        在线客服
        <br />点击此处和客服在线聊天
      </div>
      <div class="img-btn" @click="kefu">
        <img src="@a/images/icon_kefu.png" alt />
      </div>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" placement="left">
      <div slot="content" class="content">
        售后热线
        <br />400-903-907 按0转人工
      </div>
      <div class="img-btn" @click="copy('400903907')">
        <img src="@a/images/icon_iPhone.png" alt />
      </div>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content placement="left">
      <div slot="content" class="content img-content">
        <span v-if="$browserType() != 'PC'">
          微信公众号
          <br />灯塔工作室 imx6688
        </span>
        <div class="PC-box" v-if="$browserType() == 'PC'">
          <div class="img-box">
            <img src="@a/images/dt_icon.jpg" alt />
          </div>
          <div class="img-text">扫一扫，关注公众号</div>
        </div>
      </div>
      <div class="img-btn" @click="copy('imx6688')">
        <img src="@a/images/icon_wechat.png" alt />
      </div>
    </el-tooltip>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    kefu() {
      window.open(
        "https://cschat-ccs.aliyun.com/index.htm?tntInstId=_1szVJCi&scene=SCE00007256"
      );
    },
    copy(str) {
      this.$copy(str);
      this.$message.success({
        message: "复制成功",
      });
    },
  },
};
</script>
<style scoped lang='less'>
.side-bar {
  position: fixed;
  right: 27px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  z-index: 9;
  .img-btn + .img-btn {
    margin: 10px 0 0;
  }
  .img-btn {
    padding: 0;
    line-height: unset;
    border: 1px solid #dcdfe6;
    border-radius: 12px;
    width: 60px;
    height: 60px;
  }
  .img-btn img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.content {
  color: #fff;
  line-height: 20px;
  span {
    color: #fff;
  }
}
.img-content {
  padding: 0;
  .PC-box {
    .img-box {
      width: 120px;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .img-text {
      color: #fff;
    }
  }
}
@media screen and (max-width: 1200px) {
  .side-bar {
    right: 20px;
    .img-btn {
      width: 45px;
      height: 45px;
    }
  }
}
</style>
<style lang="less">
.el-tooltip__popper {
  overflow-x: unset !important;
}
.el-message__content {
  overflow-x: unset !important;
}
</style>