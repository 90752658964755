<template>
  <div id="box">
    <div id="navbar" class="nav">
      <div class="container center-vertically">
        <img id="logo" src="@a/images/logo@3x.png" alt />
        <ul id="menu">
          <li class="lili">
            <router-link :to="{ name: 'Home' }" active-class="active1" exact
              >首页</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Product' }" active-class="active"
              >冰凡产品</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Firm' }" active-class="active"
              >关于冰凡</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Callme' }" active-class="active"
              >联系我们</router-link
            >
          </li>
          <!-- <li>
            <router-link :to="{ name: 'Message' }" active-class="active"
              >招聘信息</router-link
            >
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleScroll() {
      //改变元素#searchBar的top值
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop);
      // var offsetTop = document.querySelector("#box").offsetTop;

      if (scrollTop >= 100) {
        document.querySelector("#navbar").style.backgroundImage =
          "url(/img/zp_bg@3x.b54547b1.png)";
        document.querySelector("#navbar").style.backgroundRepeat = "no-repeat";
        document.querySelector("#navbar").style.backgroundSize = "100vw";
      }
      if (scrollTop < 100) {
        document.querySelector("#navbar").style.backgroundImage = "none";
      }
    },
  },
  mounted() {
    //给window添加一个滚动滚动监听事件
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
html {
  overflow-x: hidden;
}
#box {
  width: 100%;
  height: 100px;
}
#navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  height: 100px;
  line-height: 100px;
  text-align: center;
  margin: 0 auto;
  overflow-y: hidden;
}
.container {
  height: 100px;
  width: 100%;
  margin: 0 auto;
  overflow-y: hidden;
}
.container img {
  height: 100px;
  padding-left: 8%;
}
#logo {
  display: block;
  height: 55px;
  margin-top: 30px;
  float: left;
}

#fullscreen-menu-btn {
  display: none;
}

#fullscreen-menu {
  display: none;
}

#menu {
  float: right;
  margin: 2px 0 0 0;
  padding-right: 10%;
}
#menu li .active {
  color: #fff;
}
#menu li .active1 {
  color: #404040;
}
#menu li {
  display: inline-block;
  margin-left: 45px;
  font-size: 16px;
}
#menu li a {
  color: #4d4d4d;
}
#menu li:first-child {
  margin-left: 0;
}

#menu a {
  color: #ffffff;
  text-decoration: none;
  padding: 5px;
}

#menu a.selected {
  position: relative;
}

#menu a.selected:after {
  content: "";
  background-color: #ffffff;
  height: 2px;
  position: absolute;
  left: 3px;
  right: 3px;
  bottom: -4px;
  border-radius: 5px;
}
#menu .lili a {
  color: #404040;
}
#navbar.light {
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
}

#navbar.light #menu a {
  color: #000000;
}

#navbar.light #menu a.selected {
  color: #ff4f1c;
}
#navbar.light #menu a.selected:after {
  background-color: #ff4f1c;
}

/* 媒体查询适配 */
@media screen and (max-width: 1200px) {
  #navbar {
    line-height: 0;
    text-align: left;
  }
  .container {
    height: auto;
    width: 98%;
    margin: 0 auto;
    overflow-y: hidden;
  }
  #box {
    width: 100%;
    height: 100px;
  }
  #logo {
    display: block;
    width: 112px;
    height: 46px;
    margin-top: 15px;
    padding-left: 9px;
    float: none;
  }
  #menu {
    /* margin: 2px 0 0 0; */
    padding-right: 0;
    float: none;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  #menu li {
    display: inline-block;
    margin-left: 0;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }
}
</style>