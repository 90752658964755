<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import Home from "./components/Home"
export default {
  name: 'App',
  components: {
    // Home
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #333;
  overflow-x: hidden;
}
html{
  user-select:none;
  font-family: "PingFang SC";
}
</style>
