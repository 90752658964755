<template>
  <div class="callme">
    <navbar></navbar>
    <img class="imagess" src="@a/images/us_nr@3x.png" alt />
    <div class="main">
      <h1>
        联系我们
        <img src="@a/images/icon_yumaobi@3x.png" alt />
      </h1>
      <div class="mainTop">
        <h2>公司地址</h2>
        <p>地址: 广东省深圳市宝安区新安街道海滨社区N23区海天路15-3号卓越宝中时代广场二期C栋1510</p>
        <p>电话: 0755-2969 1472</p>
      </div>
      <div class="guangao">
        <h2>广告招商</h2>
        <p>bingFun888888@163.com</p>
      </div>
    </div>
    <sidebar></sidebar>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Sidebar from "@/components/Sidebar";
export default {
  components: {
    Navbar,
    Sidebar,
    Footer,
  },
};
</script>
<style scoped>
* {
  list-style: none;
}
/* 底部样式 */
#footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.callme {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow: hidden;
  background-image: url(../assets/images/us_bg.png);
  background-repeat: no-repeat;
  background-size: 100vw 600px;
  background-position: top left;
  position: relative;
}
.imagess {
  position: absolute;
  left: 65%;
  top: 100px;
}
.callme .main {
  padding-left: 233px;
  padding-top: 100px;
}
.callme .main h1 {
  font-size: 60px;
  color: #fff;
}
.callme .main .mainTop {
  padding: 20px 0 20px 0;
}
.callme .main h2 {
  font-size: 20px;
  color: #fff;
}
.callme .main p {
  font-size: 14px;
  color: #fff;
}
.callme .main .guangao p {
  padding-bottom: 175px;
}

/* 媒体查询 */
@media screen and (max-width: 1200px) {
  #footer {
    width: 100vw;
    height: 60px;
    line-height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .callme {
    position: relative;
    width: 100vw;
    overflow-x: hidden;
    overflow: hidden;
    background-image: url(../assets/images/us_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 475px;
    background-position: top left;
    position: relative;
  }
  .imagess {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 58%;
    top: 30%;
  }
  .callme .main {
    padding-left: 17px;
    padding-top: 30px;
  }
  .callme .main h1 {
    font-size: 30px;
    color: #fff;
  }
  h1 img {
    width: 20px;
    height: 20px;
    display: inline-block;
  }
  .callme .main h2 {
    font-size: 16px;
    color: #fff;
  }
  .callme .main p {
    font-size: 12px;
    color: #fff;
  }
}
</style>